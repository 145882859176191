import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import { s3ToCDN } from '@doltech/utils/lib/url';
import cl from 'classnames';
import Image from 'next/image';
import * as React from 'react';

import {
  DolFooterDictionaryWrapper,
  DolFooterGrammarWrapper,
  DolFooterWrapper,
  DolLogoDictionaryWrapper,
  DolLogoGrammarWrapper,
  DolLogoWithoutTextWrapper,
  DolLogoWrapper,
} from './layout';

interface DolLogoProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: 'small' | 'default';
  withText?: boolean;
  colored?: boolean;
}

export const DolIeltsDinhLucLogo = (props: DolLogoProps) => {
  const { variant = 'default', withText, colored, className, ...rest } = props;

  return (
    <DolLogoWrapper className={cl('brand-logo', 'old', className, variant)} {...rest}>
      <Image
        priority
        src={s3ToCDN(
          'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Dol_Ielt_Dinh_Luc_Logo_a745bcc638.png'
        )}
        alt="logo"
        layout="fill"
        objectFit="contain"
        loader={imgProxyLoader}
      />
    </DolLogoWrapper>
  );
};

export const DolLogo = (props: DolLogoProps) => {
  const { variant = 'default', withText, colored, className, ...rest } = props;

  return (
    <DolLogoWrapper className={cl('brand-logo', 'tuhoc-logo', className, variant)} {...rest}>
      <img
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/ea9593fe-e5c9-4552-8155-e624522d9bf1.png"
        alt="logo"
      />
    </DolLogoWrapper>
  );
};

export const DolLogoFooter = (props) => {
  const { className, ...rest } = props;
  return (
    <DolFooterWrapper className={cl('brand-logo-footer', 'tuhoc-logo', className)} {...rest}>
      <img
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/4f794345-7a4c-471d-a103-9ec4821f7941.png"
        alt="logo-footer"
      />
    </DolFooterWrapper>
  );
};

export const DolDictionaryLogoFooter = (props) => {
  const { className, ...rest } = props;
  return (
    <DolFooterDictionaryWrapper
      className={cl('brand-logo-footer', 'dictionary-logo', className)}
      {...rest}
    >
      <img
        src={'https://media.dolenglish.vn/directus-upload/1e2fedbb-46fb-4c7d-9673-2f5654ec42db.png'}
        alt="logo-footer-dictionary"
      />
    </DolFooterDictionaryWrapper>
  );
};

export const DolDictionaryLogo = (props) => {
  const { className, ...rest } = props;
  return (
    <DolLogoDictionaryWrapper className={cl('brand-logo', 'dictionary-logo', className)} {...rest}>
      <img
        src="https://media.dolenglish.vn/directus-upload/d24d2848-357a-4f0d-a836-d455717862d8.png"
        alt="logo-dictionary"
      />
    </DolLogoDictionaryWrapper>
  );
};

export const DolGrammarLogoFooter = (props) => {
  const { className, ...rest } = props;
  return (
    <DolFooterGrammarWrapper className={cl('brand-logo-footer', 'grammar', className)} {...rest}>
      <img
        width={160}
        height={40}
        loading="lazy"
        src={s3ToCDN(
          'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/directus-upload/c0c50722-71b1-4394-8981-16e952c1e1fb.png'
        )}
        alt="DOL Grammar"
      />
    </DolFooterGrammarWrapper>
  );
};

export const DolGrammarLogo = (props) => {
  const { className, ...rest } = props;
  return (
    <DolLogoGrammarWrapper className={cl('brand-logo', 'grammar', className)} {...rest}>
      <img
        width={96}
        height={32}
        src={s3ToCDN('/PUBLIC/MEDIA/logo-dol-grammar.png')}
        alt="DOL Grammar"
        loading="eager"
      />
    </DolLogoGrammarWrapper>
  );
};

export const DolLogoWithoutText = (props) => {
  const { className, ...rest } = props;
  return (
    <DolLogoWithoutTextWrapper className={cl('brand-logo', 'without-text', className)} {...rest}>
      <Image
        src={s3ToCDN(
          'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Logo_Without_Text_e8f9b7308a.png'
        )}
        alt="logo-dictionary"
        layout="fill"
        objectFit="contain"
        loader={imgProxyLoader}
      />
    </DolLogoWithoutTextWrapper>
  );
};

export const DolLogoBeta = (props) => {
  const { className, ...rest } = props;
  return (
    <DolLogoWithoutTextWrapper className={cl('brand-logo', 'without-text', className)} {...rest}>
      <Image
        src="https://media.dolenglish.vn/directus-upload/dba5ee46-4500-45ac-81db-1bbb3abdee8a.png"
        alt="logo-beta"
        layout="fill"
        objectFit="contain"
      />
    </DolLogoWithoutTextWrapper>
  );
};
