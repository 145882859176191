const normalizeBase64ForImgProxy = (base64Str: string) => {
  if (base64Str) {
    return base64Str.replace(/\//g, '_');
  }

  return base64Str;
};

export function imgProxyLoader({ src, width, quality = 70 }) {
  if (!src) {
    return null;
  }
  if (src?.includes('/_next/static')) {
    return src;
  }
  let encodeSrc = src;
  try {
    if (typeof window === 'undefined') {
      encodeSrc = Buffer.from(src).toString('base64');
    } else {
      encodeSrc = btoa(src);
    }
    encodeSrc = normalizeBase64ForImgProxy(encodeSrc);
  } catch (ex) {
    console.log('Cant proxy image:', src);
    return src;
  }
  return `https://images.dolenglish.vn/rs:auto:::0/w:${width}/q:${quality}/${encodeSrc}`;
}

export function graphCmsLoader({ src, width, quality }: any) {
  const match = /^(https?:\/\/asset.dolenglish.vn)(?:\/[^/]+)?\/([^/]+)$/.exec(src);

  if (!match) {
    return imgProxyLoader({ src, width, quality });
  }

  const [prefix, handle] = match.slice(1);
  return `${prefix}/resize=w:${width}/${handle}`;
}
